/*import ComputerizationPreview from '../../../static/img/services/computerization-preview.jpg';
*/
import DesignPreview from '../../../static/img/services/design-preview.jpg';
import SocialPreview from '../../../static/img/services/social-preview.jpg';
import SoftwarePreview from '../../../static/img/services/software-preview.jpg';

export default [
  {
    name: "Desarrollo Web",
    description: [
      "Desarrollo aplicaciones web, aplicaciones de gestión, diseño de sitios web presentacionales, páginas corporativoa, tiendas online (ecommerce), inmobiliarias, etc."
    ],
    background: SoftwarePreview
  },
  {
    name: "Diseño Gráfico",
    description: [  
      "Acompañamos la creación de tu imagen.",
      "Diseñamos tu marca o la llevamos a nuevos territorios. Te ayudamos a llegar a más personas, a abrir tu emprendimiento a nuevas posibilidades y seguir en un continuo crecimiento."
    ],
    background: DesignPreview
  },
  {
    name: "Redes Sociales",
    description: [
      "Gestión de redes sociales y asesoramiento para hacer un uso eficiente de los distintos medios de comunicación. Generamos y diseñamos el contenido de los medios digitales para mantener una activa comunicación con el público objetivo de tu proyecto y atraer nueva audiencia."
    ],
    background: SocialPreview
  }
  /*,
  {
    name: "Informatización de Oficinas",
    description: [
      "Organizamos la forma de trabajo en tu oficina",
      "Te proponemos un sistema centralizado de trabajo corporativo, facilitando la cooperación de forma integrada de los miembros de tu organización y mejorando los tiempos de desarrollo de tareas comunes a fin de optimizar los resultados finales."
    ],
    background: ComputerizationPreview
  }*/
];
