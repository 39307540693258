
import React from 'react';
import background from '../../../static/img/services/accountability.jpg';
import ContactBox from '../../components/ContactBox.js';
import Hero from '../../components/Hero.js';
import Navbar from '../../components/Navbar.js';
import SectionHeading from '../../components/Sectionheading.js';
import Service from '../../components/Service.js';
import services from '../../data/services/software';
import DefaultLayout from '../../layouts/Default.js';


const hero = {
  title: 'Desarrollo & Diseño Web',
  background
};

const links = [
  { label: "INICIO", href: "#application" },
  { label: "SERVICIOS", href: "#services"}
];

export default () => (
  <DefaultLayout>
    <Navbar kind="secondary" links={ links } />

    <Hero {...hero } />

    <SectionHeading id="services" title="Servicios" />

    <section className="services">
      <div className="container">
        { services.map((service, index) => (
          <Service key={ index } {...service} />
        )) }
      </div>
    </section>

    <br /><br />

    <div className="container-min">
      <ContactBox />
    </div>
  </DefaultLayout>
);